exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-geo-heatmap-geo-controls-js": () => import("./../../../src/pages/GeoHeatmap/GeoControls.js" /* webpackChunkName: "component---src-pages-geo-heatmap-geo-controls-js" */),
  "component---src-pages-geo-heatmap-geo-json-heatmap-overlay-js": () => import("./../../../src/pages/GeoHeatmap/GeoJsonHeatmapOverlay.js" /* webpackChunkName: "component---src-pages-geo-heatmap-geo-json-heatmap-overlay-js" */),
  "component---src-pages-home-app-js": () => import("./../../../src/pages/Home/App.js" /* webpackChunkName: "component---src-pages-home-app-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-localities-interactive-points-js": () => import("./../../../src/pages/Localities/InteractivePoints.js" /* webpackChunkName: "component---src-pages-localities-interactive-points-js" */),
  "component---src-pages-map-base-map-js": () => import("./../../../src/pages/Map/BaseMap.js" /* webpackChunkName: "component---src-pages-map-base-map-js" */),
  "component---src-pages-map-map-navigation-control-buttons-js": () => import("./../../../src/pages/Map/MapNavigation/ControlButtons.js" /* webpackChunkName: "component---src-pages-map-map-navigation-control-buttons-js" */),
  "component---src-pages-starting-zoom-component-sections-about-section-js": () => import("./../../../src/pages/StartingZoom/ComponentSections/AboutSection.js" /* webpackChunkName: "component---src-pages-starting-zoom-component-sections-about-section-js" */),
  "component---src-pages-starting-zoom-component-sections-action-section-js": () => import("./../../../src/pages/StartingZoom/ComponentSections/ActionSection.js" /* webpackChunkName: "component---src-pages-starting-zoom-component-sections-action-section-js" */),
  "component---src-pages-starting-zoom-component-sections-maya-intro-js": () => import("./../../../src/pages/StartingZoom/ComponentSections/MayaIntro.js" /* webpackChunkName: "component---src-pages-starting-zoom-component-sections-maya-intro-js" */),
  "component---src-pages-starting-zoom-dev-zoom-js": () => import("./../../../src/pages/StartingZoom/DevZoom.js" /* webpackChunkName: "component---src-pages-starting-zoom-dev-zoom-js" */),
  "component---src-pages-starting-zoom-zoom-front-load-screen-js": () => import("./../../../src/pages/StartingZoom/ZoomFrontLoadScreen.js" /* webpackChunkName: "component---src-pages-starting-zoom-zoom-front-load-screen-js" */),
  "component---src-pages-tutorial-tutorial-joyride-js": () => import("./../../../src/pages/Tutorial/TutorialJoyride.js" /* webpackChunkName: "component---src-pages-tutorial-tutorial-joyride-js" */),
  "component---src-pages-widget-bubble-charts-expandable-button-js": () => import("./../../../src/pages/Widget/BubbleCharts/ExpandableButton.js" /* webpackChunkName: "component---src-pages-widget-bubble-charts-expandable-button-js" */),
  "component---src-pages-widget-custom-tooltip-interactive-points-js": () => import("./../../../src/pages/Widget/CustomTooltip_InteractivePoints.js" /* webpackChunkName: "component---src-pages-widget-custom-tooltip-interactive-points-js" */),
  "component---src-pages-widget-localities-image-grid-js": () => import("./../../../src/pages/Widget/LocalitiesImageGrid.js" /* webpackChunkName: "component---src-pages-widget-localities-image-grid-js" */),
  "component---src-pages-widget-widget-consolidation-js": () => import("./../../../src/pages/Widget/WidgetConsolidation.js" /* webpackChunkName: "component---src-pages-widget-widget-consolidation-js" */)
}

